import { Http } from "@/http";
import { Id, res_list, req_list } from "@/types/global";

export interface add_admin {
  role: number | string;
  username: string;
  password?: string;
  status: number | string;
  phone: string;
}
export interface admin extends add_admin {
  id: Id;
  create_time: string;
  update_time: string;
}

class HttpAdmin extends Http {
  get_list = (data: req_list) => {
    return this.get<res_list<admin>>({ ...data });
  };

  get_info = (id: number) => {
    return this.get<admin>({}, `/admin/admin/${id}/`);
  };

  create = (data: add_admin) => {
    return this.post(data);
  };

  edit = ({ id, ...data }: admin) => {
    return this.patch(data, `/admin/admin/${id}/`);
  };

  del_one = (id: number) => {
    return this.delete(id);
  };
}

export const api_admin = new HttpAdmin("/admin/admin/");
