



























































































import { Mixin_list } from "@/mixin";
import { api_admin, admin } from "@/http/admin";
import { Component } from "vue-property-decorator";
import Page from "@/components/page/index.vue";
import Table from "@/components/table/index.vue";
import { ElForm } from "element-ui/types/form";

@Component({
  components: {
    Page,
    Table,
  },
})
export default class extends Mixin_list<admin>(api_admin.get_list) {
  validatorPass = (rule: any, value: any, callback: any) => {
    if (value === "") {
      callback(new Error("不能为空"));
    } else if (
      !/^(?=.*\d)(?=.*[a-zA-Z])(?=.*[~!@#$%^&*])[\da-zA-Z~!@#$%^&*]{8,16}$/.test(
        value
      )
    ) {
      callback(new Error("密码必须包含数字、字母、特殊字符且8-16位字符"));
    } else {
      callback();
    }
  };
  validatorConfirmPass(rule: any, value: any, callback: any) {
    if (value === "") {
      callback(new Error("不能为空"));
    } else if (
      !/^(?=.*\d)(?=.*[a-zA-Z])(?=.*[~!@#$%^&*])[\da-zA-Z~!@#$%^&*]{8,16}$/.test(
        value
      )
    ) {
      callback(new Error("密码必须包含数字、大小写字母、特殊字符且8-16位字符"));
    } else if (value !== this.form.password) {
      callback(new Error("密码不一致"));
    } else {
      callback();
    }
  }
  async confirm() {
    await (this.$refs["form"] as ElForm | undefined)?.validate();
    await api_admin.edit(this.form);
    this.showForm = false;
    this.$message.success("修改成功");
  }
  showForm = false;
  form: any = {};
  rules = {
    password: [{ required: true, validator: this.validatorPass }],
    confirm_password: [
      { required: true, validator: this.validatorConfirmPass },
    ],
  };
  filter = {
    username: "",
  };

  columns = [
    {
      label: "员工姓名",
      prop: "username",
    },
    {
      label: "登录账号",
      prop: "account",
    },
    {
      label: "岗位名称",
      prop: "role_name",
    },
    {
      label: "状态",
      prop: "status",
      slot: "status",
    },
    {
      label: "添加时间",
      prop: "create_time",
    },
    {
      label: "操作",
      prop: "",
      width: 300,
      slot: "operation",
    },
  ];
  // click_cell(row: admin) {
  //   return this.$router.push(`/auth/admin/edit/?id=${row.id}`);
  // }

  async del(id: number) {
    await api_admin.del_one(id);
    this.get_list();
  }
  async statusChange(e:any,row:any){
    await api_admin.edit({ ...row });
        this.get_list();
  }
  reset(){
    this.filter.username=''
    this.get_list();
  }
}
